/**
 * Redirects to a URL with additional query parameters
 * @param baseUrl The base URL to redirect to
 * @param params Key-value pairs of query parameters to add
 */
export function redirectWithParams(baseUrl: string, params: Record<string, string>): void {
  const redirectUrl = new URL(baseUrl);

  Object.entries(params).forEach(([key, value]) => {
    redirectUrl.searchParams.set(key, value);
  });

  window.location.replace(redirectUrl.toString());
}
