import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { lsApi } from '../service/lsApi/lsApi';

import app from './modules/app/slice';
import contact from './modules/contact/slice';
import property from './modules/property/slice';
import prospect from './modules/prospect/slice';
import cart from './modules/cart/slice';
import { rtkQueryErrorLogger } from './middlewares/rtkQueryErrorLogger';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      [lsApi.reducerPath]: lsApi.reducer,
      app,
      contact,
      property,
      prospect,
      cart,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(lsApi.middleware, rtkQueryErrorLogger),
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
