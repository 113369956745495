import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { updateApp } from '../modules/app/slice';
import { EmailAlreadyTakenError } from '../../constants/GraphQLErrors';

const validationErrors = [EmailAlreadyTakenError].map((e) => e.error);

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const { message } = action.payload as { message: string };
      const error = message.split('.')[0];
      if (!validationErrors.includes(error)) {
        Sentry.captureMessage(message);
      }
      dispatch(updateApp({ error }));
    }

    return next(action);
  };
