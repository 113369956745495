import * as Sentry from '@sentry/react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Events } from '../enums/events';
import { StepId, Vertical } from '../enums/schema';
import { selectProspect } from '../store/modules/prospect/slice';
import { selectProperty } from '../store/modules/property/slice';
import {
  PayloadBuilder,
  TrackCallClickedInput,
  TrackCommonArgs,
  TrackHelpArticleExpandedInput,
  TrackHelpViewedInput,
  TrackScheduleSubmittedInput,
  TrackPageViewedInput,
  TrackPromocodeAddedInput,
  TrackStartDateSubmittedInput,
  TrackCrossSellSelectedInput,
  TrackActionCompletedInput,
  TrackCompleteRegistrationInput,
  TrackErrorViewedInput,
} from '../types/track';
import { useStepNavigation } from './useStepNavigation';

type PayloadBuilderType<T extends Events> = T extends keyof PayloadBuilder ? PayloadBuilder[T] : never;

export const useTrackPayload = <T extends Events>(event: T) => {
  const { stepId } = useParams();
  const step = location.pathname.split('/').pop()!;

  /**
   * We have to consume the stepId from the global path
   * because the <Header /> component layout is not wrapped
   * by the react router context. Any
   * other use case should rely on useParams() approach
   */
  const targetStepId = !stepId ? step : stepId;

  const { vertical } = useStepNavigation({ stepId: stepId as StepId });
  const { id: prospectId } = useSelector(selectProspect);
  const { name, address1: street, city, state, zip } = useSelector(selectProperty);

  const formatVertical = (vertical: Vertical): string => {
    switch (vertical) {
      case Vertical.LeafRemoval:
        return 'Leaf Removal';
      default:
        return vertical;
    }
  };

  const commonPayload: TrackCommonArgs = {
    customer_signup_page: targetStepId as StepId,
    name,
    prospect_hash_id: prospectId,
    primary_interest: formatVertical(vertical),
    address: {
      street,
      city,
      state,
      zip,
    },
  };

  let payloadBuilder: PayloadBuilderType<T> | (() => null) = () => null;

  switch (event) {
    case Events.HelpViewed:
      payloadBuilder = (({ helpContext }: TrackHelpViewedInput) => ({
        ...commonPayload,
        help_context: helpContext,
      })) as PayloadBuilderType<T>;
      break;
    case Events.HelpArticleExpanded:
      payloadBuilder = (({ articleTitle, helpContext }: TrackHelpArticleExpandedInput) => ({
        ...commonPayload,
        help_context: helpContext,
        selected_article: _.kebabCase(articleTitle),
      })) as PayloadBuilderType<T>;
      break;
    case Events.ScheduleSubmitted:
      payloadBuilder = (({ frequency, startDate, price, promocode }: TrackScheduleSubmittedInput) => ({
        ...commonPayload,
        frequency,
        start_date: startDate,
        price,
        promocode,
      })) as PayloadBuilderType<T>;
      break;
    case Events.OnAddressChange:
    case Events.ContactSubmitted:
    case Events.NotLookingForClicked:
      payloadBuilder = (() => ({
        ...commonPayload,
      })) as PayloadBuilderType<T>;
      break;
    case Events.StartDateSubmitted:
      payloadBuilder = (({ startDate }: TrackStartDateSubmittedInput) => ({
        ...commonPayload,
        start_date: startDate,
      })) as PayloadBuilderType<T>;
      break;
    case Events.PageViewed:
      payloadBuilder = (({ promocode }: TrackPageViewedInput) => ({
        ...commonPayload,
        promocode,
      })) as PayloadBuilderType<T>;
      break;
    case Events.CrossSellSelected:
      payloadBuilder = (({ crossSell }: TrackCrossSellSelectedInput) => ({
        ...commonPayload,
        cross_sell: crossSell,
      })) as PayloadBuilderType<T>;
      break;
    case Events.PromocodeAdded:
      payloadBuilder = (({ promocode }: TrackPromocodeAddedInput) => ({
        ...commonPayload,
        promocode,
      })) as PayloadBuilderType<T>;
      break;
    case Events.CallClicked:
      payloadBuilder = (({ signupContext }: TrackCallClickedInput) => ({
        ...commonPayload,
        customer_signup_context: signupContext,
      })) as PayloadBuilderType<T>;
      break;
    case Events.ActionCompleted:
      payloadBuilder = (({ action, selectedFrequency, prices, email }: TrackActionCompletedInput) => ({
        ...commonPayload,
        prices,
        email,
        customer_signup_action: action,
        selected_frequency: selectedFrequency,
      })) as PayloadBuilderType<T>;
      break;
    case Events.InitiateCheckout:
      payloadBuilder = (() => ({
        ...commonPayload,
      })) as PayloadBuilderType<T>;
      break;
    case Events.CompleteRegistration:
      payloadBuilder = (({ email }: TrackCompleteRegistrationInput) => ({
        ...commonPayload,
        email,
      })) as PayloadBuilderType<T>;
      break;
    case Events.ErrorViewed:
      payloadBuilder = (({ errorMesage, errorDetails }: TrackErrorViewedInput) => ({
        ...commonPayload,
        error_message: errorMesage,
        error_details: errorDetails,
      })) as PayloadBuilderType<T>;
      break;
    case Events.CustomerSignupLaunched:
      payloadBuilder = (() => ({
        ...commonPayload,
      })) as PayloadBuilderType<T>;
      break;
    default:
      Sentry.captureMessage(`No payload builder for ${event}`);
  }

  return { payloadBuilder };
};
