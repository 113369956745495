import { lsApi } from '../../../service/lsApi/lsApi';
import { updateProspect } from './slice';
import {
  AcceptEstimateDocument,
  AcceptEstimateMutation,
  AcceptEstimateMutationVariables,
  ApplyPromoCodeDocument,
  ApplyPromoCodeMutation,
  ApplyPromoCodeMutationVariables,
  AttachServicesToExistingOrderDocument,
  AttachServicesToExistingOrderMutation,
  AttachServicesToExistingOrderMutationVariables,
  CreateProspectDocument,
  CreateProspectMutation,
  CreateProspectMutationVariables,
  CreateQuoteDocument,
  CreateQuoteMutation,
  CreateQuoteMutationVariables,
  GetPromoCodeDocument,
  GetPromoCodeQuery,
  GetPromoCodeQueryVariables,
  GetProspectDocument,
  GetProspectQuery,
  GetProspectQueryVariables,
  IdentifyEmailDocument,
  IdentifyEmailMutation,
  IdentifyEmailMutationVariables,
  RefreshAvailableServicesForProspectDocument,
  RefreshAvailableServicesForProspectMutation,
  RefreshAvailableServicesForProspectMutationVariables,
  SubmitProspectAsOrderDocument,
  SubmitProspectAsOrderMutation,
  SubmitProspectAsOrderMutationVariables,
} from './operations';
import { updateProperty } from '../property/slice';
import { updateContact } from '../contact/slice';
import { updateApp } from '../app/slice';

const prospectApi = lsApi.injectEndpoints({
  endpoints: (build) => ({
    createProspect: build.mutation<CreateProspectMutation, CreateProspectMutationVariables>({
      query: (variables) => ({ document: CreateProspectDocument, variables }),
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        dispatch(updateApp({ error: null }));
        /* istanbul ignore next */
        queryFulfilled
          .then(({ data }) => {
            data.createProspect?.prospect && dispatch(updateProspect(data.createProspect.prospect));
          })
          .catch(() => {});
      },
    }),
    getProspect: build.query<GetProspectQuery, GetProspectQueryVariables>({
      query: (variables) => ({ document: GetProspectDocument, variables }),
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data: { prospect } }) => {
            if (!prospect) {
              return;
            }

            const { address, contact } = prospect;
            dispatch(updateProspect(prospect));
            if (address) {
              const { street, city, state, zip, country, suiteNumber } = address;
              const targetAddress = { address1: street, address2: suiteNumber, city, state, zip, country };
              dispatch(updateProperty(targetAddress));
            }

            contact && dispatch(updateContact({ ...contact }));
          })
          .catch(() => {});
      },
    }),
    acceptEstimate: build.mutation<AcceptEstimateMutation, AcceptEstimateMutationVariables>({
      query: (variables) => ({ document: AcceptEstimateDocument, variables }),
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            data.acceptEstimate?.prospect && dispatch(updateProspect(data.acceptEstimate?.prospect));
          })
          .catch(() => {});
      },
    }),
    submitProspectAsOrder: build.mutation<SubmitProspectAsOrderMutation, SubmitProspectAsOrderMutationVariables>({
      query: (variables) => ({ document: SubmitProspectAsOrderDocument, variables }),
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            data.submitProspectAsOrder && dispatch(updateProspect(data.submitProspectAsOrder));
          })
          .catch(() => {});
      },
    }),
    applyPromoCode: build.mutation<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>({
      query: (variables) => ({ document: ApplyPromoCodeDocument, variables }),
    }),
    getPromoCode: build.query<GetPromoCodeQuery, GetPromoCodeQueryVariables>({
      query: (variables) => ({ document: GetPromoCodeDocument, variables }),
    }),
    refreshAvailableServicesForProspect: build.mutation<
      RefreshAvailableServicesForProspectMutation,
      RefreshAvailableServicesForProspectMutationVariables
    >({
      query: (variables) => ({ document: RefreshAvailableServicesForProspectDocument, variables }),
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            data.refreshAvailableServicesForProspect &&
              dispatch(updateProspect(data.refreshAvailableServicesForProspect));
          })
          .catch(() => {});
      },
    }),
    attachServicesToExistingOrder: build.mutation<
      AttachServicesToExistingOrderMutation,
      AttachServicesToExistingOrderMutationVariables
    >({
      query: (variables) => ({ document: AttachServicesToExistingOrderDocument, variables }),
    }),
    createQuote: build.mutation<CreateQuoteMutation, CreateQuoteMutationVariables>({
      query: (variables) => ({ document: CreateQuoteDocument, variables }),
      onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            data.createQuote && dispatch(updateProspect({ services: [data.createQuote] }));
          })
          .catch(() => {});
      },
    }),
    identifyEmail: build.mutation<IdentifyEmailMutation, IdentifyEmailMutationVariables>({
      query: (variables) => ({ document: IdentifyEmailDocument, variables }),
    }),
  }),
});

export const {
  // Queries
  useGetProspectQuery,
  useLazyGetProspectQuery,
  useGetPromoCodeQuery,
  useLazyGetPromoCodeQuery,
  // Mutations
  useAcceptEstimateMutation,
  useCreateProspectMutation,
  useSubmitProspectAsOrderMutation,
  useApplyPromoCodeMutation,
  useRefreshAvailableServicesForProspectMutation,
  useAttachServicesToExistingOrderMutation,
  useCreateQuoteMutation,
  useIdentifyEmailMutation,
} = prospectApi;
