import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { result } from 'lodash';
import { useCallback } from 'react';
import { updateApp } from '../../../../../store/modules/app/slice';
import { useDispatch } from 'react-redux';
import { lsI18NService } from '../../../../../service';

interface useStripeFormProps {
  setPaymentToken: (token: string) => void;
}

export const useStripeForm = ({ setPaymentToken }: useStripeFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      try {
        const card = elements?.getElement(CardNumberElement) as StripeCardNumberElement;
        const result = await stripe?.createToken(card, { address_country: 'US' });

        if (result?.token) {
          dispatch(updateApp({ error: null }));
          setPaymentToken(result.token.id);
        } else if (result?.error) {
          dispatch(updateApp({ error: lsI18NService.t('formErrors.declinedCard') }));
        }
      } catch (e) {
        dispatch(updateApp({ error: lsI18NService.t('genericError') }));

        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, stripe],
  );

  return { handleSubmit, stripe: result };
};
