import { VITE_TURNSTILE_ON } from '../config/env';

export const APP_NAME = 'signup-web';
export const MOBILE_QUERY = { query: '(max-width: 639px)' };
export const TRUST_AND_SAFETY_FEE = 299;
export const LOADING_DELAY_IN_MS = 5000;
export const CAPTCHA_ACTIVATED = VITE_TURNSTILE_ON === 'true';
export const SIGNUP_WEB_IDENTIFIER = 'scalable-mowing';
export const SIGNUP_WEB_CHANNEL = 'signup';
export const SOCIAL_PROOF_BASE_VALUE = 40;
export const STATIC_MAPS_BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';
export const TOAST_MS_TO_HIDE = 2000;
export const ONE_YEAR_IN_SECONDS = 31536000;
