import { VerticalSchema } from '../types/schema';
import { StepId, StepType, Vertical, VerticalType } from '../enums/schema';
import { VerticalSelection } from '../pages/Cart/VerticalSelection';
import { Policies } from '../enums/policies';
import { Service } from '../enums/service';
import { AccountAndPayment } from '../pages/Cart/AccountAndPayment';
import { ContactInfo } from '../pages/Cart/ContactInfo';
import { ExtraServices } from '../pages/Cart/ExtraServices';
import { LawnTreatment } from '../pages/Cart/LawnTreatment';
import { QuoteQuestions } from '../pages/Cart/QuoteQuestions';
import { Scheduling } from '../pages/Cart/Scheduling';
import { Welcome } from '../pages/Cart/Welcome';
import { Checkout as QuestionCheckout, Scheduling as QuestionScheduling } from '../enums/questions';
import { Titles } from '../enums/titles';
import { leafRemovalQuestions } from '../constants/quoteQuestions';
import { Cycles } from '../enums/cycles';
export const schema: VerticalSchema = {
  vertical: Vertical.LeafRemoval,
  type: VerticalType.InstantQuote,
  service: Service.LeafRemoval,
  isPromocodeEnabled: false,
  steps: [
    {
      id: StepId.VerticalSelection,
      type: StepType.Cart,
      component: VerticalSelection,
    },
    {
      id: StepId.ContactInfo,
      type: StepType.Cart,
      component: ContactInfo,
      options: {
        calculationScreen: {
          title: Titles.FoundProperty,
        },
      },
    },
    {
      id: StepId.QuoteQuestions,
      type: StepType.Cart,
      component: QuoteQuestions,
      options: {
        questions: [...leafRemovalQuestions],
      },
    },
    {
      id: StepId.Scheduling,
      type: StepType.Cart,
      component: Scheduling,
      options: {
        recommendedCycle: Cycles.Monthly,
        showBackButton: true,
        faq: [
          QuestionScheduling.WhatsIncludedLr,
          QuestionScheduling.WhoWillBeServicingLr,
          QuestionScheduling.HowCanIBeSureLr,
          QuestionScheduling.DoIHaveToBeHomeLr,
        ],
      },
    },
    {
      id: StepId.AccountAndPayment,
      type: StepType.Cart,
      component: AccountAndPayment,
      options: {
        tos: [Policies.FortyEight],
        faq: [
          QuestionCheckout.INeedToCancel,
          QuestionCheckout.WillIBeCharged,
          QuestionCheckout.HowDoIPay,
          QuestionCheckout.ChargedTaxes,
        ],
      },
    },
    {
      id: StepId.ExtraServices,
      type: StepType.Cart,
      component: ExtraServices,
      services: [Service.BushTrimming, Service.TreeCare, Service.Cleanups, Service.Landscaping, Service.Other],
    },
    {
      id: StepId.LawnTreatment,
      type: StepType.Cart,
      component: LawnTreatment,
    },
    {
      id: StepId.Welcome,
      type: StepType.Cart,
      component: Welcome,
    },
  ],
};
