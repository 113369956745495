import { Brand } from '@lawnstarter/ls-react-common/enums';

export interface PhoneData {
  raw: string;
  formatted: string;
}

export interface BrandMetadata {
  displayName: string;
  favicon: string;
  phone: PhoneData;
  helpCenterUrl: string;
}

export const METADATA: Record<Brand, BrandMetadata> = {
  [Brand.LawnStarter]: {
    displayName: 'LawnStarter',
    favicon: '/images/lawnstarter/favicon.png',
    phone: {
      raw: '18668221766',
      formatted: '1-866-822-1766',
    },
    helpCenterUrl: 'https://support.lawnstarter.com',
  },

  [Brand.LawnLove]: {
    displayName: 'Lawn Love',
    favicon: '/images/lawnlove/favicon.png',
    phone: {
      raw: '18007064117',
      formatted: '1-800-706-4117',
    },
    helpCenterUrl: 'https://support.lawnlove.com',
  },

  [Brand.HomeGnome]: {
    displayName: 'HomeGnome',
    favicon: '/images/homegnome/favicon.png',
    phone: {
      raw: '18338448659',
      formatted: '1-833-844-8659',
    },
    helpCenterUrl: 'https://support.homegnome.com',
  },
};
