import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { updateContact } from '../../../store/modules/contact/slice';
import { store } from '../../../store';
import { selectContact } from '../../../store/modules/contact/slice';
import { EmailAlreadyTakenError } from '../../../constants/GraphQLErrors';
import { useSubmitProspectAsOrderMutation, useIdentifyEmailMutation } from '../../../store/modules/prospect/api';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { Events } from '../../../enums/events';
import { CustomerSignupAction, Price } from '../../../types/track';
import { trackActionCompleted, trackCompleteRegistration } from '../../../service/segment/trackers';
import { selectProspect } from '../../../store/modules/prospect/slice';
import { centsToDollar } from '../../../helpers/currency';
import { StepId, StepType } from '../../../enums/schema';
import { Logs } from '../../../enums/logs';
import { lsLogger } from '../../../helpers/logger';
import { lsSegmentService } from '../../../service/segment/segment';
import { VITE_BRAND } from '../../../config/env';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  termsCheckbox: '',
};

interface AccountAndPaymentProps {
  stripeFormRef: React.RefObject<HTMLFormElement>;
  checkboxRef: React.RefObject<HTMLFormElement>;
}

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const useAccountAndPayment = ({ stripeFormRef, checkboxRef }: AccountAndPaymentProps) => {
  const { hashId: prospectId } = useParams();
  const [submitProspectAsOrder, { data, isSuccess, isLoading, error }] = useSubmitProspectAsOrderMutation();
  const { name, phone } = useSelector(selectContact);
  const prospect = useSelector(selectProspect);
  const selectedEstimates = prospect?.selectedServiceEstimates;
  const [isStripeFormValid, setIsStripeFormValid] = useState(false);
  const [shouldHighlightError, setShouldHighlightError] = useState(false);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const fullName = name?.split(' ');

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { payloadBuilder: paymentAction, payloadBuilder: accountAction } = useTrackPayload(Events.ActionCompleted);
  const { payloadBuilder: completeRegistrationPayload } = useTrackPayload(Events.CompleteRegistration);

  const { control, getValues, setError, watch, trigger, clearErrors, setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues,
      firstName: fullName?.[0] || defaultValues.firstName,
      lastName: fullName?.[1] || defaultValues.lastName,
    },
  });
  const [isEmailAlreadyTaken, setIsEmailAlreadyTaken] = useState(false);

  const { firstName, lastName, email } = watch();

  const [identifyEmail] = useIdentifyEmailMutation();

  // Identify EMAIL on every change
  useEffect(() => {
    if (!email) return;

    const debouncedIdentify = _.debounce(async () => {
      if (EMAIL_REGEX.test(email)) {
        lsSegmentService.identify({ email });

        await identifyEmail({
          email,
          brandId: VITE_BRAND,
          phone: phone || '',
        });
      }
    }, 500);

    debouncedIdentify();

    return () => debouncedIdentify.cancel();
  }, [email, phone, identifyEmail]);

  /**********************************************************************************************
   * REDIRECTION
   */

  useEffect(() => {
    const pathname = `/${StepType.Cart}/${prospectId}/${StepId.Scheduling}`;
    if (!locationState) {
      lsLogger(Logs.InvalidLocationState);
      navigate({ pathname });
    } else if (!selectedEstimates || !selectedEstimates.length) {
      lsLogger(Logs.InvalidSelectedEstimates);
      navigate({ pathname });
    }
  }, [locationState, navigate, selectedEstimates, prospectId]);

  /**********************************************************************************************
   * HANDLERS
   */

  // Handle BE response errors
  useEffect(() => {
    const { message, error: emailError } = EmailAlreadyTakenError;

    if (error?.message?.includes(emailError)) {
      setIsEmailAlreadyTaken(true);
      setError('email', { type: 'error', message });
    }
  }, [error, setError]);

  const handleSubmit = useCallback(async () => {
    const isValid = await trigger();
    const { termsCheckbox } = getValues();

    if (!termsCheckbox) {
      setShouldHighlightError(true);
      checkboxRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (isValid) {
      setShouldHighlightError(false);
      stripeFormRef.current?.requestSubmit();
    }
  }, [checkboxRef, getValues, stripeFormRef, trigger]);

  // Once payment token is available, submit the prospect as an order
  useEffect(() => {
    if (!paymentToken || isLoading) return;

    const { firstName, lastName, email } = getValues();
    const contact = { name: `${firstName} ${lastName}`, phone, email };

    store.dispatch(updateContact({ email: getValues().email, name: `${firstName} ${lastName}` }));
    submitProspectAsOrder({ prospectId, contact, paymentToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentToken]);

  /**********************************************************************************************
   * TRACKERS
   */

  const getPricesAndFrequency = () => {
    if (!selectedEstimates) return { prices: [], selectedFrequency: undefined };

    const prices = selectedEstimates.map(
      (estimate) =>
        ({
          service: _.kebabCase(estimate.type),
          frequency: estimate.selectedEstimate?.cycle,
          price: centsToDollar(estimate.selectedEstimate?.amount),
        }) as Price,
    );

    const selectedFrequency = selectedEstimates[0]?.selectedEstimate?.cycle;

    return { prices, selectedFrequency };
  };

  // Track PAYMENT action
  useEffect(() => {
    if (!isStripeFormValid || !selectedEstimates) return;

    const { prices, selectedFrequency } = getPricesAndFrequency();

    const payload = paymentAction({
      action: CustomerSignupAction.PAYMENT,
      selectedFrequency,
      prices,
    });

    payload && trackActionCompleted(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStripeFormValid, selectedEstimates]);

  // Track ACCOUNT action on every change
  useEffect(() => {
    const debouncedAccountAction = _.debounce(() => {
      if (selectedEstimates && firstName && lastName && email) {
        const { prices, selectedFrequency } = getPricesAndFrequency();

        const payload = accountAction({
          action: CustomerSignupAction.ACCOUNT,
          email,
          selectedFrequency,
          prices,
        });

        payload && trackActionCompleted(payload);
      }
    }, 500);

    debouncedAccountAction();

    return () => debouncedAccountAction.cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, selectedEstimates]);

  // Track registration completion on success
  useEffect(() => {
    if (data?.submitProspectAsOrder) {
      const payload = completeRegistrationPayload({ email });
      payload && trackCompleteRegistration(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, email]);

  return {
    handleSubmit,
    setPaymentToken,
    setIsStripeFormValid,
    isEmailAlreadyTaken,
    shouldHighlightError,
    form: {
      control,
      trigger,
      clearErrors,
      setValue,
    },
    mutation: {
      data,
      isSuccess,
      isLoading,
    },
  };
};
