import { Accordion, Text } from '@lawnstarter/ls-react-common/atoms';
import { List } from 'react-native-paper';
import { lsI18NService } from '../../../../../service';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';
import { Events } from '../../../../../enums/events';
import { useCallback } from 'react';
import { trackHelpArticleExpanded } from '../../../../../service/segment/trackers';
import { METADATA } from '../../../../../constants/metadata';
import { VITE_BRAND } from '../../../../../config/env';
import { Brand } from '@lawnstarter/ls-react-common/enums';

export const Questions = () => {
  const { helpCenterUrl } = METADATA[VITE_BRAND as Brand];
  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (articleTitle: string) => {
      const payload = payloadBuilder({ articleTitle, helpContext: 'lawn_treatment' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <>
      {[...Array(4).keys()].map((_, i) => (
        <Accordion
          title={lsI18NService.t(`lawnTreatment.faq.question${i + 1}.title`)}
          trackID={`faq-treatment-${i}`}
          testID={`faq-treatment-${i}`}
          key={i}
          onOpen={() => onOpen(lsI18NService.t(`lawnTreatment.faq.question${i + 1}.title`))}
        >
          <ul>
            <li className="pl-2">
              <List.Item
                left={() => <Text>&#x25CF;</Text>}
                titleNumberOfLines={0}
                title={lsI18NService.t(`lawnTreatment.faq.question${i + 1}.content`)}
              />
            </li>
          </ul>
        </Accordion>
      ))}
      <Accordion
        onOpen={() => onOpen(lsI18NService.t(`lawnTreatment.faq.helpcenter.title`))}
        title={lsI18NService.t(`lawnTreatment.faq.helpcenter.title`)}
        trackID="faq-treatment-helpcenter"
        testID="faq-treatment-helpcenter"
      >
        <ul>
          <li className="flex py-2">
            <span className="ml-2 mr-3 text-sm">&#x25CF;</span>
            <p className="inline">
              {lsI18NService.t(`lawnTreatment.faq.helpcenter.content`)}
              <a href={helpCenterUrl} target="_blank" rel="noopener noreferrer" className="ml-1 underline text-primary">
                {lsI18NService.t(`lawnTreatment.faq.helpcenter.cta`)}
              </a>
            </p>
          </li>
        </ul>
      </Accordion>
    </>
  );
};
