import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectApp } from '../store/modules/app/slice';
import { ErrorToast } from '../components/ErrorToast';
import { lsI18NService } from '../service';
import { GraphQLCustomErrors, GraphQLHandledErrors } from '../constants/GraphQLErrors';
import { useTrackPayload } from './useTrackPayload';
import { Events } from '../enums/events';
import { trackCallClicked, trackErrorViewed } from '../service/segment/trackers';
import { CustomerSignupContext, ErrorMessage } from '../types/track';
import { VITE_BRAND } from '../config/env';
import { METADATA } from '../constants/metadata';
import { Brand } from '@lawnstarter/ls-react-common/enums';

export function useErrorHandler() {
  const { error: newError } = useSelector(selectApp);
  const [error, setError] = useState<string | null>(null);
  const { payloadBuilder: errorViewed } = useTrackPayload(Events.ErrorViewed);
  const { payloadBuilder: callClicked } = useTrackPayload(Events.CallClicked);
  const { phone } = METADATA[VITE_BRAND as Brand];

  const onCall = () => {
    const payload = callClicked({ signupContext: CustomerSignupContext.GENERIC_ERROR });
    payload && trackCallClicked(payload);

    window.location.assign(`tel:${phone.raw}`);
  };

  useEffect(() => {
    // To avoid unexpected re-renders on modal we should not update the error state here
    if (newError !== error) {
      setError(newError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newError]);

  const isErrorHandled = useMemo(() => (error ? GraphQLHandledErrors.includes(error) : true), [error]);

  useEffect(() => {
    if (isErrorHandled) return;

    const payload = errorViewed({
      errorMesage: ErrorMessage.UnhandledError,
      errorDetails: newError,
    });

    payload && trackErrorViewed(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorHandled]);

  const description = useMemo(() => GraphQLCustomErrors.find((e) => e.error === error)?.message, [error]);

  return {
    error,
    component: () =>
      !isErrorHandled && (
        <ErrorToast
          description={description || lsI18NService.t('genericError', { phone: phone.formatted })}
          msToHide={5000}
          className="fixed w-[98%] mx-[1%] bottom-4 z-10"
          action={
            !description
              ? {
                  label: lsI18NService.t('callToSignUp.callNow'),
                  onPress: onCall,
                }
              : undefined
          }
        />
      ),
  };
}
