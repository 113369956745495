import { useEffect, useMemo, useState } from 'react';
import { VITE_LEGACY_SIGNUP_PORTAL } from '../config/env';
import { Service } from '../enums/service';
import { QueryString } from '../enums/storage';
import { lsLogger } from '../helpers/logger';
import { Logs } from '../enums/logs';
import { useSearchParams } from 'react-router-dom';
import { redirectWithParams } from '../utils/navigation';
import { useTrackPayload } from './useTrackPayload';
import { Events } from '../enums/events';
import { trackCustomerSignupLaunched } from '../service/segment/trackers';

export function useGateway() {
  const [searchParams] = useSearchParams();
  const [isConcluded, setIsConcluded] = useState(false);
  const intent = useMemo(() => searchParams.get(QueryString.Intent) as string, [searchParams]);
  const isTreatment = useMemo(() => intent === Service.Treatment, [intent]);
  const { payloadBuilder: customerSignupLaunched } = useTrackPayload(Events.CustomerSignupLaunched);

  useEffect(() => {
    const payload = customerSignupLaunched();
    payload && trackCustomerSignupLaunched(payload);

    if (isTreatment) {
      lsLogger(Logs.RedirectToLegacySignup);
      redirectWithParams(VITE_LEGACY_SIGNUP_PORTAL, Object.fromEntries(searchParams.entries()));
      return;
    }

    setIsConcluded(true);
  }, [isTreatment, customerSignupLaunched, searchParams]);

  return { isConcluded };
}
