import { StepId } from './schema';

export enum Logs {
  // GLOBALS
  StatusTransferred = '[useCheckValidState] Prospect with status transferred',
  StatusCompleted = '[useCheckValidState] Prospect with status completed',
  InvalidState = '[useCheckValidState] Prospect with invalid contact or address information',
  NonNavigableRoutes = '[useCheckValidState] No redirection',
  RedirectToLegacySignup = '[useGateway] Redirecting to legacy signup for treatment intent',
  // LOCALS
  InvalidLocationState = `[${StepId.AccountAndPayment}] Invalid locationState`,
  InvalidSelectedEstimates = `[${StepId.AccountAndPayment}] Invalid selectedEstimates`,
}
