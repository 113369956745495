import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { selectProperty } from '../../../store/modules/property/slice';
import { ProspectServiceEstimate } from '../../../types/graphql';
import { StepId, StepType, VerticalType } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { useAcceptEstimateMutation, useLazyGetProspectQuery } from '../../../store/modules/prospect/api';
import {
  selectEstimatesByService,
  selectEstimateSelectedByService,
  selectProspect,
} from '../../../store/modules/prospect/slice';
import { lsI18NService } from '../../../service';
import { SchedulingStep } from '../../../types/schema';
import { Events } from '../../../enums/events';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { trackScheduleSubmitted } from '../../../service/segment/trackers';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { Status } from '../../../enums/prospect';
import { useSchema } from '../../../hooks/useSchema';
import { LOADING_DELAY_IN_MS } from '../../../constants/general';

dayjs.extend(utc);
dayjs.extend(timezone);

const stepId = StepId.Scheduling;

export const useScheduling = () => {
  const navigate = useNavigate();
  const { schema } = useSchema();
  const { goToNextStep, vertical, currentStep: step } = useStepNavigation({ stepId });
  const currentStep = step as SchedulingStep;
  const shouldDisplayStartDate = currentStep.options?.shouldDisplayStartDate !== false;

  const property = useSelector(selectProperty);
  const estimates = useSelector((state) => selectEstimatesByService(state, schema.service));
  const selectedServiceEstimates = useSelector((state) => selectEstimateSelectedByService(state, schema.service));
  const { hashId: prospectId } = useParams();
  const dispatch = useDispatch();
  const [getProspect, getProspectQuery] = useLazyGetProspectQuery();
  const prospect = useSelector(selectProspect);

  const [estimate, setEstimate] = useState<Partial<ProspectServiceEstimate>>({});
  const [startDate, setStartDate] = useState(dayjs(new Date()).add(2, 'days'));

  const [acceptEstimate, acceptEstimateMutation] = useAcceptEstimateMutation();

  const { payloadBuilder } = useTrackPayload<Events.ScheduleSubmitted>(Events.ScheduleSubmitted);
  const { promocode } = usePromoCode();

  // The recommended cycle should be the first one
  const estimatesOrdered = useMemo(
    () =>
      [...estimates].sort((estimate) => {
        const isRecommended = estimate.cycle === currentStep.options?.recommendedCycle;
        return isRecommended ? -1 : 1;
      }),
    [currentStep.options?.recommendedCycle, estimates],
  );

  const selectOptions = useMemo(
    () =>
      estimatesOrdered.map((estimate) => ({
        key: estimate.id,
        entity: estimate,
        label: lsI18NService.t(`cycle.${estimate.cycle}`),
        price: estimate.amount,
      })),
    [estimatesOrdered],
  );

  const hasEstimates = useMemo(() => estimatesOrdered.length > 0, [estimatesOrdered.length]);

  const isProspectValid = useMemo(
    () => prospect.id === prospectId && prospect.status !== Status.Completed,
    [prospectId, prospect.id, prospect.status],
  );

  const hasError = useMemo(() => isProspectValid && !hasEstimates, [hasEstimates, isProspectValid]);

  // Resolves the initial state of the Step
  useEffect(() => {
    if (!isProspectValid || !estimatesOrdered.length) return;

    if (selectedServiceEstimates?.selectedEstimate && selectedServiceEstimates.preferredStartDate) {
      setEstimate(selectedServiceEstimates.selectedEstimate);

      const timezone = dayjs.tz.guess();
      const today = dayjs().tz(timezone);
      const minimumStartDate = today.add(2, 'days');
      const preferredStartDate = dayjs(selectedServiceEstimates.preferredStartDate);

      setStartDate(preferredStartDate.isAfter(minimumStartDate) ? preferredStartDate : minimumStartDate);
      return;
    }

    setEstimate(estimatesOrdered[0]);
  }, [estimatesOrdered, isProspectValid, selectedServiceEstimates]);

  /**
   * Globally fetches the prospect information
   * from Signup API when customer comes back
   * to the portal. This GET operation shouldn't
   * be executed more than once
   */
  useEffect(() => {
    if (!prospectId || isProspectValid) {
      return;
    }

    if (getProspectQuery.isUninitialized) {
      getProspect({ id: prospectId });
    }
  }, [isProspectValid, prospectId, getProspect, dispatch, getProspectQuery.isUninitialized]);

  useEffect(() => {
    if (!getProspectQuery.isSuccess) return;

    if (prospect?.status === Status.Completed) {
      return;
    }

    if (!isProspectValid) {
      navigate(`/${StepType.Cart}/${StepId.ContactInfo}?intent=${vertical}`);
    }
  }, [getProspectQuery.isSuccess, prospect?.status, navigate, vertical, isProspectValid]);

  // Redirects to the ContactInfo step if there is an error
  useEffect(() => {
    if (!hasError) return;

    if (schema.type === VerticalType.InstantQuote) {
      navigate(`/${StepType.Cart}/${prospectId}/${StepId.QuoteQuestions}`);
    } else {
      setTimeout(() => navigate(`/${StepType.Cart}/${StepId.ContactInfo}?intent=${vertical}`), LOADING_DELAY_IN_MS);
    }
  }, [hasError, vertical, navigate, schema.type, prospectId]);

  // Redirects to the next step if the prospect is completed and track the event
  useEffect(() => {
    if (acceptEstimateMutation.isSuccess && goToNextStep) {
      const payload = payloadBuilder({
        frequency: estimate.cycle,
        startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
        promocode: String(promocode?.id),
        price: String(estimate.amount),
      });
      payload && trackScheduleSubmitted(payload);
      navigate(goToNextStep, { state: { previousStepId: stepId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptEstimateMutation.isSuccess]);

  const handleSubmit = useCallback(() => {
    acceptEstimate({ estimateId: estimate.id, startDate: startDate.format('YYYY-MM-DD HH:mm:ss') });
  }, [acceptEstimate, estimate?.id, startDate]);

  return {
    vertical,
    property,
    selectOptions,
    hasEstimates,
    hasError,
    goToNextStep,
    shouldDisplayStartDate,
    currentStep,
    query: { isLoading: getProspectQuery.isFetching },
    form: {
      handleSubmit,
      isValid: Boolean(estimate?.amount && startDate),
      estimate,
      setEstimate,
      startDate,
      setStartDate,
      mutation: acceptEstimateMutation,
    },
  };
};
