import { useCallback, useEffect } from 'react';
import { getCookie } from 'react-use-cookie';

import { VITE_BRAND } from '../../../config/env';
import { useCreateProspectMutation } from '../../../store/modules/prospect/api';
import { useSchema } from '../../../hooks/useSchema';
import { updateProperty } from '../../../store/modules/property/slice';
import { store } from '../../../store';
import { updateContact } from '../../../store/modules/contact/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectApp, updateApp } from '../../../store/modules/app/slice';
import { MutationCreateProspectArgs } from '../../../types/graphql';
import { useSearchParams } from 'react-router-dom';
import { CommonCookies, QueryString, WwwCookies } from '../../../enums/storage';
import { InvalidPromoCodeError } from '../../../constants/GraphQLErrors';
import { useProspectCookies } from './useProspectCookies';

export const useProspect = () => {
  const { schema } = useSchema();
  const [createProspect, { data, isSuccess, isLoading, isUninitialized, isError, error, reset }] =
    useCreateProspectMutation();
  const { turnstileToken, promoCode } = useSelector(selectApp);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { handleCookies } = useProspectCookies();

  const utmSource = searchParams.get(QueryString.UtmSource);
  const utmMedium = searchParams.get(QueryString.UtmMedium);
  const utmTerm = searchParams.get(QueryString.UtmTerm);
  const utmContent = searchParams.get(QueryString.UtmContent);
  const utmCampaign = searchParams.get(QueryString.UtmCampaign);
  const gclid = searchParams.get(QueryString.GoogleId);
  const fbcid = searchParams.get(QueryString.FacebookId);

  useEffect(() => {
    // isPromocodeValid may be undefined if no promo code is provided
    // Ensure the toast is only shown for truly invalid promo codes
    data?.createProspect?.isPromocodeValid === false && dispatch(updateApp({ error: InvalidPromoCodeError.error }));
  }, [data, dispatch]);

  const handleProspect = useCallback(
    (input: Partial<MutationCreateProspectArgs & { street_number?: string }>) => {
      const prospectMeta = {
        brandId: VITE_BRAND,
        services: [schema.service],
        turnstileToken,
        promoCode: input.promoCode ?? promoCode,
        segmentAnonymousId: input.segmentAnonymousId || getCookie(CommonCookies.SegmentAnonymousId),
        primaryIntent: schema.service,
      };

      const propertyInput = {
        phone: String(input.phone).replace(/\D/g, ''),
        name: String(input.name),
        address1: String(input.address1).split(',')[0],
        address2: String(input.address2),
        city: String(input.city),
        state: String(input.state),
        zip: String(input.zip),
        country: String(input.country),
      };

      const contactInput = {
        phone: String(input.phone).replace(/\D/g, ''),
        name: String(input.name),
      };

      const marketingInput = {
        utmSource,
        utmMedium,
        utmTerm,
        utmContent,
        utmCampaign,
        gclid,
        fbcid,
      };

      // Calls mutation
      createProspect({ ...prospectMeta, ...propertyInput, ...marketingInput });

      // Calls store
      store.dispatch(
        updateProperty({
          ...propertyInput,
          number: input.street_number ? String(input.street_number) : '',
        }),
      );
      store.dispatch(updateContact(contactInput));

      // Register cookies
      handleCookies({
        [WwwCookies.Name]: `"${input.name}"`,
        [WwwCookies.Address]: JSON.stringify({ ...propertyInput, street: input.address1 }),
        [WwwCookies.Phone]: `"${input.phone}"`,
      });
    },
    [
      schema.service,
      turnstileToken,
      promoCode,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      utmCampaign,
      gclid,
      fbcid,
      createProspect,
      handleCookies,
    ],
  );

  return { handleProspect, data, isSuccess, isLoading, isUninitialized, isError, error, reset };
};
